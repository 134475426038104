import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Function to get the device ID (visitor ID) using FingerprintJS
export const getDeviceId = async () => {
  // Load the FingerprintJS agent
  const fp = await FingerprintJS.load();

  // Get the visitor identifier
  const result = await fp.get();

  // Return the visitorId (device ID)
  return result.visitorId;
};
