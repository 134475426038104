// src/components/ChatBox.js
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'; // Import necessary Leaflet components
import ReactMarkdown from 'react-markdown';
import './ChatBox.css';

// Set marker icon for Leaflet (since it's broken in Leaflet by default in React projects)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ChatBox({ messages, onSendMessage, loading }) {
  const [inputMessage, setInputMessage] = useState(''); // To store user input
  const messagesEndRef = useRef(null); // Ref for tracking the bottom of the messages container

  // Scroll to bottom whenever messages change or loading changes
  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to handle sending message
  const handleSendMessage = () => {
    if (inputMessage.trim() === '') return; // Ignore empty messages
    onSendMessage(inputMessage);
    setInputMessage(''); // Clear input field after sending
  };

  // Function to handle "Enter" key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Function to extract latitude and longitude from the message, if present
  const extractCoordinates = (message) => {
    // Regex to find two decimal numbers (latitude and longitude) in the message
    const latLngRegex = /[^\d\n.]*\s*(-?\d+\.\d+)[^\d.]*(-?\d+\.\d+)/;
  
    // Check if the message contains two numbers
    const match = message.match(latLngRegex);
  
    if (match) {
      const lat = parseFloat(match[1]); // Extract latitude
      const lng = parseFloat(match[2]); // Extract longitude


      console.log(message);
  
      // Replace latitude and longitude part with an empty string or any desired replacement
      const newMessage = message.replaceAll(/[^\d\n.]*\s*(-?\d+\.\d+)[^\d.]*(-?\d+\.\d+)\n?|[^\n]*(?:(?:L|l)atitude|(?:L|l)ongitude)[^\n]*/g, '');
      // Return the latitude, longitude, and new message
      return { lat, lng, newMessage };
    }
  
    // If no latitude and longitude found, return the original message with lat and lng as null
    return { lat: null, lng: null, newMessage: message };
  };
  
  return (
    <div className="chat-box">
      <div className="chat-messages">
        {messages.map((message, index) => {
          // Extract coordinates and update message content
          const { lat, lng, newMessage } = message.role === 'assistant' ? extractCoordinates(message.content) : { newMessage: message.content };
  
          return (
            <div key={index}>
              <div className={`message-label ${message.role === 'user' ? 'user-label' : 'assistant-label'}`}>
                {message.role === 'user' ? 'You' : 'Capri Assistant'}
              </div>
              <div className={`${message.role === 'user' ? 'user-message' : 'assistant-message'}`}>
                {/* Render the message without coordinates */}
                <ReactMarkdown>{newMessage}</ReactMarkdown>
  
                {/* If coordinates exist, render a map with a marker */}
                {lat && lng && (
                  <br /> &&
                  <MapContainer
                    center={[lat, lng]}
                    zoom={13}
                    style={{ height: '300px', width: '100%', marginTop: '10px' }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[lat, lng]}>
                      <Popup>
                        Location found on map.
                      </Popup>
                    </Marker>
                  </MapContainer>
                )}
              </div>
            </div>
          );
        })}
  
        {/* Show the loading indicator as the last message */}
        {loading && (
          <div className="loading-message">
            <div className="assistant-label">Capri Assistant</div>
            <div className="assistant-message">
              <div className="spinner"></div> {/* Spinner added */}
              <span>Thinking...</span>
            </div>
          </div>
        )}
  
        {/* Empty div used as a scroll target */}
        <div ref={messagesEndRef}></div>
      </div>
  
      {/* Input field for user */}
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={handleKeyDown}  // Add key down event listener
          placeholder="Type your message here..."
          disabled={loading}  // Disable input while loading
        />
        <button onClick={handleSendMessage} disabled={loading}>Send</button>
      </div>
    </div>
  );
}  

export default ChatBox;
