import React from 'react';

function WhatsAppButton({ onClick }) {
  return (
    <div className="whatsapp-container">
      <div className="whatsapp-button" onClick={onClick}>
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
          alt="WhatsApp" 
          style={{ width: '30px', height: '30px' }} 
        />
      </div>
      <div className="whatsapp-text">मुझे लोन चाहिए</div>
    </div>
  );
}

export default WhatsAppButton;

