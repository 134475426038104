// import React from 'react';

// function Sidebar() {
//   return (
//     <div className="sidebar">
//       <img src="/home/ubuntu/capri_ai/frontend/ai-companion/src/assets/capri.png" alt="Capri AI Logo" />
//       <h2>AI-Dost for Loan Assistance</h2>
//       <p>
//         A smart virtual assistant designed to help with your loan-related queries. This AI companion provides
//         quick and personalized guidance in most Indian languages. Your data is handled securely, giving you peace of mind.
//       </p>
//       <p className="disclaimer">Disclaimer: I am an AI and may make mistakes.</p>
//     </div>
//   );
// }

// export default Sidebar;

import React from 'react';
import capriLogo from '../assets/capri_logo.png'; // Import the image from assets folder
import './Sidebar.css'; // Import the CSS file

function Sidebar() {
  return (
    <div className="sidebar">
      <img src={capriLogo} alt="Capri AI Logo" />
      <h2>AI-Dost for Loan Assistance</h2>
      <p>
        A smart virtual assistant designed to help with your loan-related queries. This AI companion provides
        quick and personalized guidance in most Indian languages. Your data is handled securely, giving you peace of mind.
      </p>
      <p className="disclaimer">Disclaimer: I am an AI and may make mistakes.</p>
      
    </div>
  );
}

export default Sidebar;
