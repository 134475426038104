import React, { useState } from 'react';
import './Modal.css'; // Import modal styling

function Modal({ show, onClose, otpSent, onOtpRequest, onOtpVerification, timer }) {
  const [details, setDetails] = useState({ name: '', email: '', phone: '' });
  const [otp, setOtp] = useState('');

  if (!show) return null; // Don't render modal if not visible

  // Handle user input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Handle form submission for user details
  const handleSubmit = (e) => {
    e.preventDefault();
    onOtpRequest(details);
  };

  // Handle OTP verification
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    onOtpVerification(otp);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        {/* Close button */}
        <button className="modal-close-btn" onClick={onClose}>×</button> {/* Close button added here */}

        <h2>लोन लेना अब है आसान,<br/>AI दोस्त के संग छुएं आसमान</h2>
        
        {!otpSent ? (
          // User details form (before OTP is sent)
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={details.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={details.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={details.phone}
              onChange={handleChange}
              required
            />
            <button type="submit">सबमिट करें</button>
          </form>
        ) : (
          // OTP input form (after OTP is sent)
          <form onSubmit={handleOtpSubmit}>
            <input
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
              required
            />
            <div className="otp-buttons">
              <button type="button" onClick={handleSubmit} disabled={timer > 0}>OTP फिर से भेजें</button>
              <button type="submit">सबमिट करें</button>
            </div>
            <p>OTP आपके मोबाइल नंबर पर भेजा जा चुका है। <br /> समय शेष: <strong>{`${Math.max(Math.floor(timer / 60), 0)}:${String(Math.max(timer % 60, 0)).padStart(2, '0')}`}</strong></p>
          </form>
        )}
      </div>
    </div>
  );
}

export default Modal;
